<template>
  <div>
    {{ scope.row.scale[targetIndex] }}
  </div>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import requestSender from "@/api/base/requestSender";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'element-scale-pay-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {
    targetIndex: {type: String, required: true}
  },
  data() {
    return {}
  },

  methods: {}
}

</script>

<style scoped lang="scss">

</style>
